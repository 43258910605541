<template>
  <v-app
    id="app"
    class="ep-bgimage"
  >
    <router-view />
    <core-footer />
  </v-app>
</template>
<script>
import { mapActions } from 'vuex'
export default {
  methods: {
    ...mapActions('account', ['checkLoggedIn']),
    ...mapActions('comms', ['getStartupMessage'])
  },
  mounted () {
    this.checkLoggedIn()
    this.getStartupMessage()
  }
}
</script>

<style lang="scss">
@import '@/styles/index.scss';
</style>
