<template>
  <v-select
    :items="appList"
    ref="selectedApp"
    item-text="name"
    item-value="id"
    return-object
    v-model="selectedApp"
  >
    <template #prepend>
      <v-img
        class="ml-2"
        height="32px"
        max-width="32px"
        :style="imgClick ? 'cursor: pointer;' : ''"
        contain
        @click="imgClicked"
        :src="selectedApp.logo || ''"
      />
    </template>
    <template #item="{ item }">
      <v-list-item-avatar>
        <v-img
          height="40px"
          max-width="40px"
          contain
          :src="item.logo || ''"
        />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-text="item.name" />
      </v-list-item-content>
    </template>
  </v-select>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  props: {
    pvalue: {
      type: Object,
      default: () => ({
        id: null,
        name: null,
        admin_level: 0,
        description: null,
        logo: null
      })
    },
    imgClick: {
      type: undefined,
      default: null
    }
  },
  data () {
    return {
      appList: [],
      value: {
        id: null,
        name: null,
        admin_level: 0,
        description: null,
        logo: null
      }
    }
  },
  mounted () {
    this.loadAppList()
  },
  computed: {
    selectedApp: {
      get () {
        return this.value
      },
      set (val) {
        this.updateValues(val)
      }
    }
  },
  methods: {
    ...mapMutations('appAdmin', ['setActiveClientApp']),
    loadAppList () {
      this.$http.get('/api/app_admin/app/')
        .then(r => {
          this.appList = r.data
          this.selectedApp = this.appList[0]
        })
    },
    updateValues (val) {
      this.setActiveClientApp(val)
      this.value = val
      this.$emit('input', val)
      this.$emit('changed', val)
    },
    imgClicked () {
      this.imgClick(this.selectedApp)
    }
  }
}
</script>
