/**
 * Vue Router
 *
 * @library
 *
 * https://router.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
// Routes
import routes from '@/views/routes.js'
import { getRoutes } from './utils.js'

const VIEWS = [
  'Account',
  'Dashboard',
  'AppAdmin'
]
const extRoutes = [
  {
    name: 'Login',
    path: '/vaccount/login',
    component: () => import(/* webpackChunkName: "account" */ '@/views/Account/Login.vue')
  },
  {
    name: 'Register',
    path: '/vaccount/register',
    component: () => import(/* webpackChunkName: "account" */ '@/views/Account/Register.vue')
  },
  {
    name: 'RegisterWizard',
    path: '/vaccount/registerwizard',
    component: () => import(/* webpackChunkName: "account" */ '@/views/Account/RegisterWizard.vue')
  },
  {
    name: 'Verification Sent',
    path: '/vaccount/verification_sent',
    component: () => import(/* webpackChunkName: "account" */ '@/views/Account/VerificationSent.vue')
  },
  {
    name: 'Expired',
    path: '/vaccount/expired/:username?',
    component: () => import(/* webpackChunkName: "account" */ '@/views/Account/Expired.vue'),
    props: false
  },
  {
    name: 'PasswordResetConfirm',
    path: '/vaccount/password-reset/confirm/:uid/:token/',
    component: () => import(/* webpackChunkName: "account" */ '@/views/Account/ResetPasswordConfirm.vue')
  }

]

routes.push(...extRoutes)
routes[0].children.push(...getRoutes(VIEWS))

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: routes
})

const loginRoutes = ['Login', 'Register', 'RegisterWizard', 'Expired', 'PasswordResetConfirm', 'Verification Sent']
router.beforeEach((to, from, next) => {
  // Utente non loggato
  if (!store.state.account.user && !loginRoutes.includes(to.name) && !loginRoutes.includes(from.name)) {
    next({ name: 'Login', query: Object.assign(from.query || {}, to.query || {}) })
    return
  } else if (store.state.account.user && loginRoutes.includes(to.name)) {
    next({ name: 'Dashboard', query: Object.assign(from.query || {}, to.query || {}) })
    return
  }
  next()
})
export default router
export {
  routes
}
