/**
 * module actions.js
 */

// import store from '@/store'
import axios from '@/plugins/axios.js'
import dialog from '@/plugins/vuetify-dialog.js'
import SsoMessageCard from '@/components/sso/MessageCard.vue'

export default {
  getStartupMessage (context) {
    axios.get('/api/comms/startupmessage/')
      .then(r => {
        if (!r.data) return
        if (context.state.lastStartupMessageId !== r.data.id) context.commit('setShowStartupMessage', true)
        console.log('uguali', context.state.lastStartupMessageId === r.data.id, context.state.showStartupMessage)
        context.commit('setLastStartupMessageId', r.data.id)
        if (context.state.showStartupMessage) {
          dialog.show(SsoMessageCard, {
            ...r.data,
            type: r.data.msg_type,
            persistent: true,
            actions: [
              {
                text: 'Ok',
                flat: false,
                outlined: false,
                color: 'primary'
              },
              {
                text: 'Non mostrare più',
                handler: () => {
                  context.commit('setShowStartupMessage', false)
                },
                flat: false,
                outlined: false,
                color: 'warning'
              }
            ]
          })
        }
      })
  }
}
