/**
 * module mutations.js
 */

export default {
  setToken (state, token) {
    state.token = token
  },
  loginUser (state, user) {
    state.user = user
    console.log(`Logged in ${user.username}`)
  },
  logoutUser (state) {
    console.log(`Logged out ${state?.user?.username || 'Vuoto'}`)
    state.token = null
    state.user = null
    state.accounts = []
  },
  setAccounts (state, accounts) {
    if (state.user) {
      state.accounts = accounts
    } else {
      throw new Error('Nessun utente attivo')
    }
  },
  addLinked (state, linked) {
    if (state.user) {
      for (var i = 0; i < state.linked.length; i++) {
        if (state.linked[i].id === linked.id) {
          state.linked[i] = linked
          return
        }
      }
      state.linked.push(linked)
    } else {
      throw new Error('Nessun utente attivo')
    }
  },
  updateEmail (state, email) {
    state.user.email = email
  },
  updatePhone (state, phone) {
    state.user.phone = phone
  },
  updateShowSsoAvatar (state, value) {
    state.user.show_sso_avatar = value
  },
  updateShowAppsAvatar (state, value) {
    state.user.show_apps_avatar = value
  }
}
