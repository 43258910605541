/**
 * module routes.js
 */

export default {
  name: '',
  path: '/appadmin',
  text: 'App Admin',
  icon: 'movie-open-outline',
  component: () => import(/* webpackChunkName: "appadmin" */ './Index.vue'),
  children: [
    {
      name: 'AppAdmin',
      path: '',
      icon: 'movie-open-outline',
      component: () => import(/* webpackChunkName: "appadmin" */ './AppAdmin.vue'),
      children: [
        {
          name: 'AppAdmin::Users',
          path: 'users',
          icon: 'mdi-account-group-outline',
          component: () => import(/* webpackChunkName: "appadmin" */ './AppUsers.vue')
        },
        {
          name: 'AppAdmin::Settings',
          path: 'settings',
          icon: 'mdi-cog',
          component: () => import(/* webpackChunkName: "appadmin" */ './AppSettings.vue')
        },
        {
          name: 'AppAdmin::UserImport',
          path: 'user_import',
          icon: 'mdi-file-import',
          component: () => import(/* webpackChunkName: "appadmin" */ './AppUserImport.vue')
        }
      ]
    }
  ]
}
