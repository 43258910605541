<template>
  <v-app-bar
    id="core-toolbar"
    color="primary"
    dark
    dense
    src="/static/img/sso-2.svg"
  >
    <template #img="{ props }">
      <v-img
        v-bind="props"
        position="left top"
        height="100%"
        style="opacity: 0.5"
        gradient="to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"
      />
    </template>
    <v-toolbar-title class="white--text ml-md-3 hidden-xs-only">
      Essepaghe SSO [{{ title }}]
    </v-toolbar-title>
    <v-spacer />
    <v-toolbar-title
      v-if="user"
      class="white--text mr-4"
    >
      Benvenuto {{ `${user.first_name} ${user.last_name}`.trim() || user.username }} !
    </v-toolbar-title>
    <v-tooltip
      class="hidden-xs-only"
      bottom
    >
      <template #activator="{ on }">
        <v-btn
          class="hidden-xs-only"
          icon
          v-ripple
          to="/dashboard/"
          v-on="on"
          :disabled="toolBarDisabled"
        >
          <v-icon color="tertiary">
            mdi-view-dashboard
          </v-icon>
        </v-btn>
      </template>
      <span>Dashboard</span>
    </v-tooltip>
    <v-menu
      v-if="user"
      v-model="menu"
      class="ml-4"
      :close-on-content-click="true"
      :nudge-width="200"
      :disabled="toolBarDisabled"
      offset-x
      offset-y
    >
      <template #activator="{ on: onMenu }">
        <v-tooltip
          bottom
        >
          <template #activator="{ on: onTooltip }">
            <v-avatar
              v-on="{...onMenu, ...onTooltip}"
              :size="32"
              class="float-sm-right"
            >
              <v-gravatar
                :email="showSsoAvatar ? user.email : ''"
                alt="Nobody"
                :size="32"
                default-img="mm"
              />
            </v-avatar>
          </template>
          <span>Menu</span>
        </v-tooltip>
      </template>
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-avatar
              :size="80"
            >
              <v-gravatar
                :email="showSsoAvatar ? user.email : ''"
                alt="Nobody"
                :size="80"
                default-img="mm"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ user.username }}</v-list-item-title>
              <v-list-item-subtitle>{{ user.email }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider />
        <v-list>
          <template v-for="(item, idx) in accountItems">
            <v-divider
              v-if="item.divider"
              :key="idx"
              :inset="item.inset"
            />
            <v-list-item
              v-else
              :key="item.text"
              :to="item.link || null"
              v-on="item.click ? {click: item.click} : {}"
            >
              <v-list-item-action>
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-item-action>
              <v-list-item-title
                v-text="item.text"
              />
            </v-list-item>
          </template>
        </v-list>
      </v-card>
    </v-menu>
  </v-app-bar>
</template>

<script>

import { mapState, mapActions } from 'vuex'

export default {
  data () {
    return {
      fav: null,
      message: null,
      hints: null,
      error: null,
      menu: false,
      title: null,
      responsive: false
    }
  },
  computed: {
    ...mapState('account', ['user']),
    ...mapState('app', ['toolBarDisabled']),
    showSsoAvatar () {
      return this.$store.state.account.user.show_sso_avatar === true
    },
    accountItems () {
      const items = [
        {
          icon: 'mdi-view-dashboard',
          text: 'Dashboard',
          link: '/dashboard/'
        }
      ]
      if (this.user.is_appadmin) {
        items.push(
          {
            icon: 'fa fa-users-cog',
            text: 'Amministra app',
            link: '/appadmin/'
          }
        )
      }
      items.push(
        {
          icon: 'mdi-account',
          text: 'Gestisci account',
          link: '/vaccount/'
        },
        { divider: true, inset: true },
        {
          icon: 'mdi-logout',
          text: 'Esci',
          click: () => {
            this.logoutUser()
            this.$router.push('/')
          }
        }
      )
      return items
    }
  },
  watch: {
    '$route' (val) {
      this.title = val.name
    }
  },
  mounted () {
    this.onResponsiveInverted()
    window.addEventListener('resize', this.onResponsiveInverted)
    this.title = this.$route.name
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.onResponsiveInverted)
  },

  methods: {
    ...mapActions('account', ['logoutUser']),
    doLogout () {
      this.logoutUser()
      this.$router.push('/')
    },
    onResponsiveInverted () {
      if (window.innerWidth < 991) {
        this.responsive = true
      } else {
        this.responsive = false
      }
    }
  }
}
</script>

<style lang="scss">
#core-toolbar {
  .v-toolbar__content {
    -ms-flex-direction: row;
    flex-direction: row;
  }
  margin-bottom: 0;
  a {
    text-decoration: none;
  }
}
</style>
