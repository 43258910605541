import colors from 'vuetify/es5/util/colors'
export default {
  // nothemes: {
  //  light: {
  //    primary: '#4caf50',
  //    secondary: '#4caf50',
  //    tertiary: '#495057',
  //    accent: '#82B1FF',
  //    error: '#f55a4e',
  //    info: '#00d3ee',
  //    success: '#5cb860',
  //    warning: '#ffa21a'
  //  }
  // },
  themes: {
    light: {
      accent: colors.lightBlue.lighten2,
      error: colors.red.base,
      danger: colors.red.base,
      info: colors.lightBlue.base,
      primary: colors.lightBlue.darken4,
      primaryLight: colors.blue.darken3,
      secondary: colors.lightBlue.darken2,
      success: colors.green.base,
      warning: colors.deepOrange.base,
      /**
   * personalizzati.
   */
      buttonPrimary: colors.blue.darken3,
      epButtonDisabled: '#718596',
      footerIcon: colors.lightBlue.lighten5,
      menuIcon: colors.blue.darken4,
      tabs: '#ECECEC',
      tabsSlider: colors.lightBlue.lighten1
    }
  }
}
