/**
 * module routes.js
 */
import store from '@/store'

export default {
  name: '',
  path: '/vaccount',
  icon: 'mdi-account',
  text: 'Account',
  component: () => import(/* webpackChunkName: "account" */ './Index.vue'),
  children: [
    {
      name: 'Account',
      path: '',
      component: () => import(/* webpackChunkName: "account" */ './Account.vue')
    },
    {
      name: 'Logout',
      path: 'logout/',
      beforeEnter: async (to, from, next) => {
        console.info('Entro before enter')
        await store.dispatch('account/logoutUser')
        if ('next' in to.query) {
          console.info('Ho il next')
          next(false)
          window.location.href = to.query.next
          return
        }
        next({ name: 'Login' })
      }
    }
  ]
}
