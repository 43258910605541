<template>
  <v-card
    :class="['sso-app-card', isLinking ? 'is-linking' : isDeleting ? 'is-deleting' : isDefaulting ? 'is-defaulting' : '']"
    elevation="4"
    outlined
    shaped
    :id="`sso-app-${app.appId}`"
  >
    <v-list-item
      class="text-truncate sso-app-card-title ma-0 sso-app-card-head"
      two-line
    >
      <v-list-item-avatar
        class="profile"
        tile
        size="64"
      >
        <img
          alt="user"
          :src="logo"
        >
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title class="text-h5">
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-list shaped>
      <v-list-item
        v-for="(account, index) in accounts"
        :id="`list-item-${account.app_id}-${account.id}`"
        :key="`${account.username}.${index}`"
        :class="['account-list-item', account.default ? 'default-account' : '', account.startLink ? 'start-link' : '' , account.endLink ? 'end-link': '']"
        :ripple="isLinking || isDeleting || isDefaulting"
        :inactive="!(isLinking || isDeleting || isDefaulting)"
        @mouseover="isLinking ? $emit('linkAccountsOver', account) : isDeleting ? $emit('deleteAccountOver', account) : isDefaulting ? $emit('setDefaultOver', account) : null"
        @mouseleave="isLinking ? $emit('linkAccountsLeave', account) : isDeleting ? $emit('deleteAccountLeave', account) : isDefaulting ? $emit('setDefaultLeave', account) : null"
        @click="isLinking ? $emit('linkAccounts', account, $event) : isDeleting ? $emit('deleteAccount', account, $event) : isDefaulting ? $emit('setDefault', account, $event) : null"
      >
        <v-list-item-avatar>
          <v-gravatar
            :email="showAppsAvatar ? account.info.email : ''"
            alt="Nobody"
            :id="`${account.app_id}-${account.id}`"
            :size="80"
            default-img="mm"
          />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            v-text="accountName(account)"
          />
        </v-list-item-content>
        <v-list-item-action>
          <v-tooltip left>
            <template #activator="{ on }">
              <v-btn
                color="secondary"
                elevation="1"
                fab
                v-on="on"
                x-small
                @click="$emit('doAppLogin', account)"
              >
                <v-icon dask>
                  mdi-login
                </v-icon>
              </v-btn>
            </template>
            <span class="text-left"><div>Accedi a <b>{{ account.name }}</b> con l'utente <b>{{ accountName(account) }}</b></div></span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>

export default {
  inheritAttrs: false,

  props: {
    app: {
      type: Object,
      default: () => ({
        appId: null,
        name: '',
        description: '',
        logo: '',
        accounts: []
      })
    },
    isLinking: {
      type: Boolean,
      default: false
    },
    isDefaulting: {
      type: Boolean,
      default: false
    },
    isDeleting: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: undefined
    },
    description: {
      type: String,
      default: undefined
    },
    accounts: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      selectedItem: 1
    }
  },
  computed: {
    showAppsAvatar () {
      return this.$store.state.account.user.show_apps_avatar === true
    }
  },
  methods: {
    accountName (account) {
      if (!account.info) return account.username
      let name = `${account.info.first_name || ''} ${account.info.last_name || ''}`.trim()
      name = (name || (account.info.description || '')).trim()
      name = name || account.username
      return name
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
$warning: #ff9800;
$warningt: #ff980066;
$defaulting: #1976d2;
$defaultingt: #1976d266;
$linking: #f9a825;
$linkingt: #f9a82566;
$end-color: rgba(25, 118, 210, 0.71);
$default-end-color: #1976d2;

.css-selector {
    background: linear-gradient(270deg, #ffee00, #ff0000);
    background-size: 400% 400%;
    -webkit-animation: LinkingStart 3s ease infinite;
    -moz-animation: LinkingStart 3s ease infinite;
    animation: LinkingStart 3s ease infinite;
}

@-webkit-keyframes LinkingStart {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@-moz-keyframes LinkingStart {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}
@keyframes LinkingStart {
    0%{background-position:0% 50%}
    50%{background-position:100% 50%}
    100%{background-position:0% 50%}
}

.sso-app-card {
  /* &.app-list-item.v-card.v-sheet.v-sheet--outlined.v-sheet--shaped.theme--light.elevation-4.sso-app-card {*/
  &.app-list-item {
    border: 2px solid $end-color;
    .v-list-item--link {
      cursor: default;
    }
    .default-account {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $default-end-color);
    }
    .sso-app-card-head {
      height: 85px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(80, 113, 142, 0.6));
      @media #{map-get($display-breakpoints, 'md-and-down')} {
        .sso-app-card-title {
          max-width: 245px;
        }
      }
      @media only screen and (max-width: 1440px) {
        .sso-app-card-title {
          max-width: 375px;
        }
      }
    }
    &.is-deleting {
      border: 2px solid $warning;
      .v-list-item--link {
        cursor: pointer;
      }
      .theme--light.v-list {
        // background-color: $warningt;
      }
      .v-list-item__action {
        visibility: hidden;
      }
      .sso-app-card-head {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $warningt);
      }
      .default-account {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $warningt);
      }
    }
    &.is-defaulting {
      border: 2px solid $defaulting;
      .v-list-item--link {
        cursor: pointer;
      }
      .v-list-item__action {
        visibility: hidden;
      }
    }
    &.is-linking {
      border: 2px solid $linking;
      .v-list-item--link {
        cursor: pointer;
      }
      .v-list-item__action {
        visibility: hidden;
      }
     .v-list-item {
       &.start-link {
          /* border: 3px dotted green; */
          background: linear-gradient(270deg, rgba(255,255,255,0) , green);
          background-size: 400% 400%;
          -webkit-animation: LinkingStart 3s ease infinite;
          -moz-animation: LinkingStart 3s ease infinite;
          animation: LinkingStart 3s ease infinite;
        }
       &.end-link {
          /* border: 3px dotted orange; */
          background: linear-gradient(270deg, rgba(255,255,255, 0), orange);
          background-size: 400% 400%;
          -webkit-animation: LinkingStart 3s ease infinite;
          -moz-animation: LinkingStart 3s ease infinite;
          animation: LinkingStart 3s ease infinite;
        }
      }
    }
  }
}
</style>
