/**
 * module index.js
 */
import './epdrag.scss'

const ElementDragger = (el) => {
  el.onmousedown = function (event) {
    if (!event.target.closest(el.epHeandler)) return

    const shiftX = event.clientX - el.getBoundingClientRect().left
    const shiftY = event.clientY - el.getBoundingClientRect().top

    // el.style.position = 'absolute'
    el.style.zIndex = 1000
    // document.body.append(el)

    moveAt(event.pageX, event.pageY)

    // moves the el at (pageX, pageY) coordinates
    // taking initial shifts into account
    function moveAt (pageX, pageY) {
      el.style.left = pageX - shiftX + 'px'
      el.style.top = pageY - shiftY + 'px'
    }

    function onMouseMove (event) {
      moveAt(event.pageX, event.pageY)
    }

    // move the el on mousemove
    document.addEventListener('mousemove', onMouseMove)

    // drop the el, remove unneeded handlers
    el.onmouseup = function () {
      document.removeEventListener('mousemove', onMouseMove)
      el.onmouseup = null
    }
  }

  el.ondragstart = function () {
    return false
  }
}
const EpDrag = {
  bind (el, binding, vnode) {
    el.style.position = 'fixed'
    var s = (binding.arg === 'left' ? 'left' : 'top')
    el.style[s] = binding.value + 'px'
    el.classList.add('ep-drag-element')
    el.epDragging = false
    el.epDraggable = true
    el.epHeandler = binding.value.handler
    el.querySelector(el.epHeandler).classList.add('ep-drag-element-handler')
    ElementDragger(el)
  },
  inserted (el) {
    // console.log('inserted', el)
  },
  update (el) {
    // console.log('update', el)
  },
  componentUpdated (el) {
    // console.log('componentUpdated', el)
  },
  unbind (el) {
    // console.log('unbind', el)
    el.onmousedown = null
  }

}
export default EpDrag
