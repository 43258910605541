<template>
  <material-card
    v-bind="$attrs"
    :class="['v-card--material-account', isDeleting ? 'is-deleting' : isDefaulting ? 'is-defaulting' : '']"
    v-on="$listeners"
    @click="isDeleting ? $emit('deleteAccount', account) : isDefaulting ? $emit('setDefault', account) : null"
    shaped
  >
    <v-card
      slot="offset"
      :class="`elevation-${elevation}`"
      :color="color"
      class="pa-4"
      dark
    >
      <v-img
        :src="logo"
        contain
      />
    </v-card>
    <div :class="['text-right', account.default ? 'default-account' : '']">
      <p
        class="category grey--text font-weight-light"
        v-text="description"
      />
      <h3 class="text-h6 account-title text-h4 font-weight-light">
        {{ title }} <small>{{ smallValue }}</small>
      </h3>
      <small v-if="account.info.ragionesociale">{{ account.info.ragionesociale }}</small>
    </div>
    <template slot="actions">
      <v-spacer />
      <v-btn
        outlined
        small
        @click="$emit('doAppLogin', account)"
        class="mr-3"
        color="success"
      >
        accedi
      </v-btn>
    </template>
  </material-card>
</template>

<script>
import Card from './Card'

export default {
  inheritAttrs: false,

  props: {
    ...Card.props,
    isDefaulting: {
      type: Boolean,
      default: false
    },
    isDeleting: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      required: true
    },
    subIcon: {
      type: String,
      default: undefined
    },
    subIconColor: {
      type: String,
      default: undefined
    },
    subTextColor: {
      type: String,
      default: undefined
    },
    subText: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    description: {
      type: String,
      default: undefined
    },
    smallValue: {
      type: String,
      default: undefined
    },
    account: {
      type: Object,
      default: () => ({})
    },
    multi: {
      type: Boolean,
      default: false
    }
  }
}
</script>
<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
$warning: #ff9800;
$warningt: #ff980066;
$defaulting: #1976d2;
$defaultingt: #1976d266;
$end-color: rgba(25, 118, 210, 0.71);
$dicon: #4caf50;

.v-card--material-account {
  border: 2px solid $end-color;

  .v-list-item--link {
    cursor: default;
  }

  .default-account {

    /* background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(25, 118, 210, 0.71)); */
    .account-title::before {
      // content: '\F0055';
      content: '\F012C';
      color: $dicon;
      display: inline-block;
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: 24px;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  &.is-defaulting {
    border: 2px solid $defaulting;

    .v-list-item--link {
      cursor: pointer;
    }

    .v-list-item__action {
      visibility: hidden;
    }
  }

  &.is-deleting {
    border: 2px solid $warning;

    .v-list-item--link {
      cursor: pointer;
    }

    .theme--light.v-list {
      // background-color: $warningt;
    }

    .v-list-item__action {
      visibility: hidden;
    }

    .sso-app-card-head {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $warningt);
    }

    .default-account {
      /* background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $warningt); */
    }
  }

  display: flex;
  flex-wrap: wrap;
  position: relative;

  .v-offset {
    display: inline-block;
    flex: 0 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0 !important;
    max-width: auto;
    padding: 0 16px 0;
  }

  .v-card {
    border-radius: 4px;
    flex: 0 1 auto;
    width: 85px;
    height: 85px;
  }

  .v-card__text {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;

    .v-list {
      text-align: left;

      .v-list-item {
        max-height: 45px;
      }
    }

    .title {
      &.account-title {
        max-width: calc(100% - 95px);
        float: right;
        text-overflow: ellipsis;
        overflow-x: hidden;
        white-space: nowrap !important;
      }
    }
  }

  .v-card__actions {
    flex: 1 0 100%;
    margin: 0 0 0 !important;
  }
}
</style>
