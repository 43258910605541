/**
 * module messages.js
 */

import store from '@/store'
import dialog from '@/plugins/vuetify-dialog.js'
import cookies from '@/plugins/vue-cookies.js'

const TYPES = {
  10: 'warning',
  20: 'info',
  25: 'success',
  30: 'warning',
  40: 'error'
}

const ICONS = {
  warning: 'mdi-alert',
  info: 'mdi-information',
  success: 'mdi-check-circle',
  error: 'mdi-alert-circle'
}

const confirm = (msg, title = 'Info', type = 'info', icon = 'mdi-information', response = true) => {
  let text = msg
  if (text instanceof Array) text = text.join('<br >')
  return dialog.confirm({
    title: title,
    text: text,
    type: type,
    icon: icon,
    actions: {
      [response]: {
        text: 'Ok',
        color: 'primary',
        outlined: true
      }
    }
  })
}

const getMessage = (msg) => {
  return errorMerge(msg?.response?.data || msg?.data || msg)
}
const error = (msg, title = 'Errore') => {
  confirm(getMessage(msg), title, 'error', 'mdi-alert-circle', false)
}
const info = (msg, title = 'Info') => {
  confirm(getMessage(msg), title)
}

const errorMerge = (data) => {
  let msgs = []
  if (data instanceof Array) return msgs.concat(data)
  if (data.non_field_errors) {
    msgs = msgs.concat(data.non_field_errors)
    delete data.non_field_errors
  }
  if (data.detail) {
    msgs = msgs.concat(data.detail)
    delete data.detail
  }
  Object.entries(data).forEach(([key, value]) => {
    if (value.forEach) {
      value.forEach(msg => {
        msgs.push(`${key}: ${msg}`)
      })
    }
  })
  return msgs.length ? msgs : data
}

const checkCookieMessages = () => {
  const messages = cookies.get('messages')
  if (messages) {
    const value = messages.substr(1, messages.length - 2).split(/\$/, 2)[1]
    const msgs = JSON.parse(
      value
        .replace(/\\u2019/g, '\'')
        .replace(/\\054/g, ',')
        .replace(/\\073/g, ';')
        .replace(/\\\\/g, '\\')
        .normalize()
        .replace(/\\/g, '')
    )
    if (store.state.account.user) {
      for (const [, , level, msg] of msgs) {
        const type = TYPES[level]
        dialog.notify[type](msg)
      }
    } else {
      for (const [, , level, msg] of msgs) {
        const type = TYPES[level]
        dialog.confirm({
          title: type,
          text: msg,
          icon: ICONS[type],
          type: type,
          // waitForResult: false,
          actions: {
            true: {
              text: 'Ok',
              color: 'primary',
              outlined: true
            }
          }
        })
      }
    }
    cookies.remove('messages')
  }
}

export default {
  error,
  info,
  errorMerge
}
export {
  checkCookieMessages,
  error,
  info,
  errorMerge
}
