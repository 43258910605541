import { set, toggle } from '@/utils/vuex'

export default {
  setDrawer: set('drawer'),
  setImage: set('image'),
  setColor: set('color'),
  toggleDrawer: toggle('drawer'),
  disableToolBar: (state) => { state.toolBarDisabled = true },
  enableToolBar: (state) => { state.toolBarDisabled = false }
}
