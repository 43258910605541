// =========================================================
// * Vuetify Material Dashboard - v1.0.0
// =========================================================

// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Licenses under MIT

// * Coded by Creative Tim

// =========================================================

// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import Vue from 'vue'
import VueMasonry from 'vue-masonry-css'

// Components
import './components'

// Plugins
import './plugins'
import { sync } from 'vuex-router-sync'
import vuetify from '@/plugins/vuetify'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib'

// Application imports
import App from './App'
import router from '@/router'
import store from '@/store'

Vue.use(VueMasonry)
Vue.use(VueTelInputVuetify, {
  vuetify,
  placeholder: '',
  validCharactersOnly: true
})

// Sync store with router
sync(store, router)

Vue.config.productionTip = false
const LOGOUT_ON_EXIT = false

/* eslint-disable no-new */
const options = {
  router,
  store,
  vuetify,
  render: h => h(App),
  created () {
    // forza il logout dell'utente alla chiusura della tab o del browser.
    window.addEventListener('beforeunload', (e) => {
      if (LOGOUT_ON_EXIT) store.dispatch('account/logoutUser')
    }, false)
  }
}
// if (process.env.NODE_ENV === 'production' && process.env.VUE_APP_FORCE_DEBUG !== 'force') {
//   new Vue(options).$mount('#app')
// } else {
global.vue = new Vue(options).$mount('#app')
// }
