/**
 * module routes.js
 */

export default
{
  name: '',
  path: '/dashboard',
  text: 'Dashboard',
  icon: 'mdi-view-dashboard',
  component: () => import(/* webpackChunkName: "dashboard" */ './Index.vue'),
  children: [
    {
      name: 'Dashboard',
      path: '',
      text: 'Dashboard',
      icon: 'mdi-view-dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ './Dashboard.vue')
    }
  ]
}
