/**
 * module axios.js
 */

import Vue from 'vue'
import axios from 'axios'
import store from '@/store'
import { checkCookieMessages } from '@/utils/messages.js'
import Loading from './loadingOverlay.js'

axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFToken'

const DEBUG = false
const axiosauth = axios.create({
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow': '*',
    'Access-Control-Expose-Headers': 'Access-Token, Access-User',
    'X-Requested-With': 'XMLHttpRequest',
    ContentType: 'application/json'
  }
})

const showOverlay = request => {
  if (typeof request.showOverlay === 'undefined') request.showOverlay = true
  if (request.showOverlay) {
    request.overlay = Loading.show({
      container: request.showOverlay === true ? null : request.showOverlay,
      isFullPage: request.showOverlay === true
    })
  }
}
const hideOverlay = request => {
  if (request.overlay) {
    request.overlay.hide()
    request.overlay = null
  }
}

axiosauth.reqInterceptor = axiosauth.interceptors.request.use((config) => {
  showOverlay(config)
  if (store.state.account.token) {
    config.headers.Authorization = `Bearer ${store.state.account.token}`
  }
  return config
}, (err) => {
  if (DEBUG) console.error('Request error', err)
  return Promise.reject(err)
})

axiosauth.resInterceptor = axiosauth.interceptors.response.use(
  response => {
    hideOverlay(response.config)
    checkCookieMessages()
    return response
  },
  err => {
    if (DEBUG) console.log('Reponse error', err)
    if (err.response) {
      hideOverlay(err.response.config)
      checkCookieMessages(err.response.data)
    }
    // error(err.response.data) vedremo come automatizzare.
    return Promise.reject(err)
  }
)

axiosauth.upload = function (url, file, onUploadProgress) {
  const formData = new FormData()
  formData.append('file', file)
  return this.put(url, formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    },
    onUploadProgress
  })
}

Vue.prototype.$http = axiosauth
export default axiosauth
