/**
 * module rotes.js
 */

export default [
  {
    path: '',
    name: 'home',
    component: () => import('./Index.vue'),
    redirect: '/dashboard/',
    children: []
  }
]
