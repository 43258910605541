/**
 * module state.js
 */

export default {
  user: null,
  accounts: [],
  linked: [],
  token: null
}
