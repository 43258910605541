/**
 * module utils.js
 */

const getRoutes = (views) => {
  const routes = []
  views.forEach(view => {
    try {
      routes.push(require(`@/views/${view}/routes.js`).default)
    } catch (error) {
      console.error(`No view ${view}`)
    }
  })
  return routes
}
export {
  getRoutes
}
