<template>
  <v-main
    app
    class="ep-bgimage"
    id="core-view"
  >
    <v-fade-transition mode="out-in">
      <v-container
        class="fill-height sso-container"
        fluid
      >
        <router-view />
      </v-container>
    </v-fade-transition>
  </v-main>
</template>

<script>
export default {
}
</script>

<style>
#core-view {
}
</style>
