<template>
  <v-list-item
    :class="['text-truncate', 'ma-0', 'sso-card-header', color]"
    two-line
  >
    <v-list-item-avatar
      v-if="avatar"
      :size="size"
    >
      <component
        :is="imgComponent"
        v-bind="imgComponentProps"
      />
    </v-list-item-avatar>
    <component
      v-else
      :is="imgComponent"
      v-bind="imgComponentProps"
    />
    <v-list-item-content
      :class="`${color}--text`"
    >
      <v-list-item-title class="text-h5 text-capitalize">
        {{ title }}
      </v-list-item-title>
      <v-list-item-subtitle>
        {{ subTitle }}
      </v-list-item-subtitle>
    </v-list-item-content>
  </v-list-item>
</template>
<script>
// import Gravatar from 'vue-gravatar'
// import { VIcon, VImg } from 'vuetify/lib'
export default {
  props: {
    color: {
      type: String,
      default: 'primary'
    },
    title: {
      type: String,
      default: undefined
    },
    subTitle: {
      type: String,
      default: undefined
    },
    icon: {
      type: String,
      default: undefined
    },
    avatar: {
      type: Boolean,
      default: false
    },
    img: {
      type: String,
      default: undefined
    },
    gravatar: {
      type: String,
      default: undefined
    },
    size: {
      type: Number,
      default: 60
    }
  },
  data () {
    return {
    }
  },
  computed: {
    imgComponent () {
      if (this.gravatar) return 'v-gravatar'
      if (this.img) return 'v-img'
      if (this.icon) return 'v-icon'
      return 'span'
    },
    imgComponentProps () {
      if (this.imgComponent === 'v-gravatar') {
        return {
          email: this.gravatar,
          size: 80,
          alt: 'Nobody',
          defaultImg: 'mm'
        }
      }
      if (this.imgComponent === 'v-img') {
        return {
          src: this.img,
          size: 80
        }
      }
      if (this.imgComponent === 'v-gravatar') {
        return {
          vText: this.icon,
          size: 80
        }
      }
      return {}
    }
  }
}
</script>
<style lang="scss">
$start-color: #FFF;
$start-opacity: 0.1;
$end-opacity: 0.8;
  .sso-card-header {
    height: 70px;
    background-color: $start-color;
    background: linear-gradient(to right, transparentize($start-color, $start-opacity), transparentize($start-color, $end-opacity));
  }
</style>
