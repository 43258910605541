/**
 * module messages.js
 */

import Vue from 'vue'
import messages from '@/utils/messages'

Vue.prototype.$msgInfo = messages.info
Vue.prototype.$msgError = messages.error
Vue.prototype.$msgErrorMerge = messages.errorMerge
