<template>
  <v-footer
    id="core-footer"
    color="white"
    outlined
    fixed
    height="42"
    class="justify-center pl-0"
    inset
    app
  >
    <div class="footer-items">
      <span
        v-for="(item, idx) in items"
        :key="idx"
        :class="`${item.color}--text`"
      >
        <a
          v-if="item.link || item.to"
          :href="item.Link"
          :to="item.to"
          :target="item.target || '_self'"
          class="tertiary--text footer-links"
        >
          <v-icon
            v-if="item.icon"
            v-text="item.icon"
          />
          {{ item.text }}
        </a>
        <span
          v-else
          class="tertiary--text footer-nolinks"
        >
          <v-icon
            v-if="item.icon"
            v-text="item.icon"
            :class="`${item.color}--text`"
            small
          />
          {{ item.text }}
        </span>
      </span>
    </div>
    <v-spacer />
    <span class="font-weight-light copyright">
      &copy;
      {{ (new Date()).getFullYear() }}
      Essepaghe
    </span>
  </v-footer>
</template>

<script>
export default {
  data: () => ({
    items: [
      // { text: 'Home', to: '/', target: null }
      // { text: 'Essepaghe', link: 'https://www.essepaghe.it', target: '_blank' }
      // { text: '(+39) 0444.91.07.41', icon: 'mdi-phone', color: 'primary' }
    ]
  })
}
</script>
<style lang="scss">
#core-footer {
  z-index: 1;
  .footer-nolinks {
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 12px;
    padding: 15px;
    line-height: 1.8;
  }
  &.theme--light.v-footer {
    border-top: 1px solid transparent;
    // background: transparent;
    // padding: 15px 24px;
  }
}
</style>
