<template>
  <v-card
    :class="['sso-account-card', isDeleting ? 'is-deleting' : isDefaulting ? 'is-defaulting': '']"
    elevation="4"
    outlined
    shaped
    :ripple="isDeleting || isDefaulting"
    @click="isDeleting ? $emit('deleteAccount', account) : isDefaulting ? $emit('setDefault', account) : null"
  >
    <v-row
      :class="['mx-0', 'sso-account-card-head', { 'default-account': account.info.default }]"
    >
      <v-col
        :cols="$vuetify.breakpoint.width < 500 ? 2 : 1"
      >
        <v-avatar
          class="profile"
          size="64"
        >
          <v-gravatar
            :email="showAppsAvatar ? account.info.email : ''"
            alt="Nobody"
            :size="64"
            default-img="mm"
          />
        </v-avatar>
      </v-col>
      <v-col>
        <v-list-item
          inactive
          class="text-truncate sso-account-card-title ml-md-2"
          two-line
        >
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ accountName(account) }}
            </v-list-item-title>
            <v-list-item-subtitle>
              <v-avatar
                tile
                class="profile"
                size="28"
              >
                <img
                  alt="user"
                  :src="logo"
                >
              </v-avatar>
              {{ description }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-tooltip left>
              <template #activator="{ on }">
                <v-btn
                  color="secondary"
                  elevation="1"
                  fab
                  v-on="on"
                  x-small
                  @click="$emit('doAppLogin', account)"
                >
                  <v-icon dask>
                    mdi-login
                  </v-icon>
                </v-btn>
              </template>
              <span class="text-left"><div>Accedi a <b>{{ account.name }}</b> con l'utente <b>{{ accountName(account) }}</b></div></span>
            </v-tooltip>
          </v-list-item-action>
        </v-list-item>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>

export default {
  inheritAttrs: false,

  props: {
    isDefaulting: {
      type: Boolean,
      default: false
    },
    isDeleting: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      required: true
    },
    subIcon: {
      type: String,
      default: undefined
    },
    subIconColor: {
      type: String,
      default: undefined
    },
    subTextColor: {
      type: String,
      default: undefined
    },
    subText: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    description: {
      type: String,
      default: undefined
    },
    smallValue: {
      type: String,
      default: undefined
    },
    account: {
      type: Object,
      default: () => ({})
    },
    accounts: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      selectedItem: 1
    }
  },
  computed: {
    showAppsAvatar () {
      return this.$store.state.account.user.show_apps_avatar === true
    }
  },
  methods: {
    accountName (account) {
      if (!account.info) return account.username
      let name = `${account.info.first_name || ''} ${account.info.last_name || ''}`.trim()
      name = (name || (account.info.description || '')).trim()
      name = name || account.username
      return name
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
$warning: #ff9800;
$warningt: #ff980066;
$defaulting: #1976d2;
$defaultingt: #1976d266;
$end-color: rgba(25, 118, 210, 0.71);
$default-end-color: #1976d2;
.sso-account-card {
  &.account-list-item {
  border: 2px solid $end-color;
  cursor: default;
  .sso-account-card-head {
    height: 85px;
    background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(80, 113, 142, 0.6));
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      .sso-account-card-title {
        /* max-width: 245px; */
      }
    }
   &.default-account {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $default-end-color);
    }
  }
    &.is-deleting {
      border: 2px solid $warning;
      cursor: pointer;
      .theme--light.v-list {
        // background-color: $warningt;
      }
      .v-list-item__action {
        visibility: hidden;
      }
      .sso-app-card-head {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $warningt);
      }
      .default-account {
        background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $warningt);
      }
    }
    &.is-defaulting {
      border: 2px solid $defaulting;
      cursor: pointer;
      // .theme--light.v-list {
      //   background-color: $defaultingt;
      // }
      .v-list-item__action {
        visibility: hidden;
      }
      // .sso-app-card-head {
      //   background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $defaultingt);
      // }
      // .default-account {
      //   background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $defaultingt);
      // }
    }
  }
}
</style>
