var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app-bar',{attrs:{"id":"core-toolbar","color":"primary","dark":"","dense":"","src":"/static/img/sso-2.svg"},scopedSlots:_vm._u([{key:"img",fn:function(ref){
var props = ref.props;
return [_c('v-img',_vm._b({staticStyle:{"opacity":"0.5"},attrs:{"position":"left top","height":"100%","gradient":"to top right, rgba(19,84,122,.5), rgba(128,208,199,.8)"}},'v-img',props,false))]}}])},[_c('v-toolbar-title',{staticClass:"white--text ml-md-3 hidden-xs-only"},[_vm._v(" Essepaghe SSO ["+_vm._s(_vm.title)+"] ")]),_c('v-spacer'),(_vm.user)?_c('v-toolbar-title',{staticClass:"white--text mr-4"},[_vm._v(" Benvenuto "+_vm._s(((_vm.user.first_name) + " " + (_vm.user.last_name)).trim() || _vm.user.username)+" ! ")]):_vm._e(),_c('v-tooltip',{staticClass:"hidden-xs-only",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"hidden-xs-only",attrs:{"icon":"","to":"/dashboard/","disabled":_vm.toolBarDisabled}},on),[_c('v-icon',{attrs:{"color":"tertiary"}},[_vm._v(" mdi-view-dashboard ")])],1)]}}])},[_c('span',[_vm._v("Dashboard")])]),(_vm.user)?_c('v-menu',{staticClass:"ml-4",attrs:{"close-on-content-click":true,"nudge-width":200,"disabled":_vm.toolBarDisabled,"offset-x":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-avatar',_vm._g({staticClass:"float-sm-right",attrs:{"size":32}},Object.assign({}, onMenu, onTooltip)),[_c('v-gravatar',{attrs:{"email":_vm.showSsoAvatar ? _vm.user.email : '',"alt":"Nobody","size":32,"default-img":"mm"}})],1)]}}],null,true)},[_c('span',[_vm._v("Menu")])])]}}],null,false,1760807007),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',[_c('v-list',[_c('v-list-item',[_c('v-list-item-avatar',{attrs:{"size":80}},[_c('v-gravatar',{attrs:{"email":_vm.showSsoAvatar ? _vm.user.email : '',"alt":"Nobody","size":80,"default-img":"mm"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.username))]),_c('v-list-item-subtitle',[_vm._v(_vm._s(_vm.user.email))])],1)],1)],1),_c('v-divider'),_c('v-list',[_vm._l((_vm.accountItems),function(item,idx){return [(item.divider)?_c('v-divider',{key:idx,attrs:{"inset":item.inset}}):_c('v-list-item',_vm._g({key:item.text,attrs:{"to":item.link || null}},item.click ? {click: item.click} : {}),[_c('v-list-item-action',[_c('v-icon',[_vm._v(_vm._s(item.icon))])],1),_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.text)}})],1)]})],2)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }