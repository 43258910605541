/**
 * module actions.js
 */

import router from '@/router'
import axios from '@/plugins/axios.js'
import messages from '@/utils/messages.js'
// import { paramsFromNext } from '@/utils/misc'

const doLoginAndNext = (data, context) => {
  axios.get('/rest-auth/user/')
    .then(r => {
      context.commit('logoutUser')
      context.commit('setToken', r.data.key)
      context.commit('loginUser', r.data)
    }).catch(() => {
      context.commit('logoutUser')
      router.push({ name: 'Login' })
    })
}

const actions = {
  registerUser (context, register) {
    const raise = register?.raise === true
    if (raise) delete register.raise
    return axios.post('/rest-auth/registration/', register, { params: router.currentRoute.query })
      .then(r => {
        messages.info(r.data)
        doLoginAndNext(r.data, context)
      }).catch(error => {
        messages.error(messages.errorMerge(error.response.data))
        if (raise) throw error
      })
  },
  checkLoggedIn (context) {
    axios.get('/rest-auth/user/')
      .then(r => {
        if (r.data.pk !== context.state?.user?.pk) {
          context.commit('logoutUser')
          context.commit('setToken', r.data.key)
          context.commit('loginUser', r.data)
        }
      })
      .catch(e => {
        context.commit('logoutUser')
      })
  },
  loginUser (context, login) {
    axios.post('/rest-auth/login/', login)
      .then(r => {
        r.data.force_dashboard = login.force_dashboard
        doLoginAndNext(r.data, context)
      }).catch(error => {
        const response = error.response
        if (response.status === 403 && response.data.detail === 'Password scaduta') {
          router.replace('/vaccount/expired/')
        } else if (response.status === 307) {
          router.replace({ path: response.data.registerUrl, query: { ...router.currentRoute.query, username: login.username } })
        } else {
          messages.error(response.data)
        }
      })
  },
  changePassword (context, change) {
    axios.post('/accounts/password/change/expired', change)
      .then(r => {
        actions.loginUser(context, {
          username: change.username,
          password: change.new_password1,
          app_id: null,
          client_id: null,
          app_login: '',
          app_password: null
        })
      })
      .catch(messages.error)
  },
  logoutUser (context) {
    axios.get('/rest-auth/logout/').then(() => context.commit('logoutUser'))
  },
  setAccounts (context, accounts) {
    context.commit('setAccounts', accounts)
  },
  updateShowSsoAvatar (context, value) {
    axios.patch('/accounts/update_show_sso_avatar/', { value })
      .then(
        context.commit('updateShowSsoAvatar', value)
      )
  },
  updateShowAppsAvatar (context, value) {
    axios.patch('/accounts/update_show_apps_avatar/', { value })
      .then(
        context.commit('updateShowAppsAvatar', value)
      )
  }
}
export default { ...actions }
