<template>
  <material-card
    shaped
    v-bind="$attrs"
    :class="['v-card--material-app', isDeleting ? 'is-deleting' : isDefaulting ? 'is-defaulting' : '']"
    v-on="$listeners"
  >
    <v-card
      slot="offset"
      :class="`elevation-${elevation}`"
      :color="color"
      class="pa-4"
      dark
    >
      <v-img
        :src="logo"
        contain
      />
    </v-card>
    <div class="text-right">
      <p
        class="category grey--text font-weight-light"
        v-text="title"
      />
      <h3 class="text-h6 text-h4 font-weight-light">
        {{ description }} <small>{{ smallValue }}</small>
      </h3>
    </div>
    <v-divider class="mt-4" />
    <v-list>
      <template v-for="(account, index) in accounts">
        <v-divider
          v-if="index"
          :key="index"
        />
        <v-list-item
          :key="`${account.username}.${index}`"
          :class="['', account.default ? 'default-account' : '']"
          @click="isDeleting ? $emit('deleteAccount', account) : isDefaulting ? $emit('setDefault', account) : null"
        >
          <v-list-item-content>
            <v-list-item-title>
              {{ account.username }}
              <span
                class="ragionesociale"
                v-if="account.info.ragionesociale"
              >
                - <span>{{ account.info.ragionesociale }}</span>
              </span>
            </v-list-item-title>
          </v-list-item-content>
          <v-list-item-action>
            <div>
              <v-btn
                small
                outlined
                @click="$emit('doAppLogin', account)"
                color="success"
              >
                accedi
              </v-btn>
            </div>
          </v-list-item-action>
        </v-list-item>
      </template>
    </v-list>
  </material-card>
</template>

<script>
import Card from './Card'

export default {
  inheritAttrs: false,

  props: {
    ...Card.props,
    isDefaulting: {
      type: Boolean,
      default: false
    },
    isDeleting: {
      type: Boolean,
      default: false
    },
    logo: {
      type: String,
      required: true
    },
    subIcon: {
      type: String,
      default: undefined
    },
    subIconColor: {
      type: String,
      default: undefined
    },
    subTextColor: {
      type: String,
      default: undefined
    },
    subText: {
      type: String,
      default: undefined
    },
    title: {
      type: String,
      default: undefined
    },
    description: {
      type: String,
      default: undefined
    },
    smallValue: {
      type: String,
      default: undefined
    },
    accounts: {
      type: Array,
      default: () => ([])
    }
  }
}
</script>

<style lang="scss">
@import '~vuetify/src/styles/settings/_variables';
$warning: #ff9800;
$warningt: #ff980066;
$defaulting: #1976d2;
$defaultingt: #1976d266;
$dicon: #4caf50;
$end-color: rgba(25, 118, 210, 0.1);

.v-card--material-app {
  border: 2px solid $end-color;

  .v-list-item--link {
    cursor: default;
  }

  .default-account {

    /* background: linear-gradient(to right, rgba(255, 255, 255, 0.8), rgba(25, 118, 210, 0.71)); */
    .v-list-item__title::before,
    .v-list-item__subtitle-a::before {
      // content: '\F0055';
      content: '\F012C';
      color: $dicon;
      display: inline-block;
      font: normal normal normal 24px/1 "Material Design Icons";
      font-size: 24px;
      text-rendering: auto;
      line-height: inherit;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }

  }

  .ragionesociale {
    font-size: 12px;
    color: rgba(0, 0, 0, .6);

    span {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
  }

  &.is-defaulting {
    border: 2px solid $defaulting;

    .v-list-item--link {
      cursor: pointer;
    }

    .v-list-item__action {
      visibility: hidden;
    }
  }

  &.is-deleting {
    border: 2px solid $warning;

    .v-list-item--link {
      cursor: pointer;
    }

    .theme--light.v-list {
      // background-color: $warningt;
    }

    .v-list-item__action {
      visibility: hidden;
    }

    .sso-app-card-head {
      background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $warningt);
    }

    .default-account {
      /* background: linear-gradient(to right, rgba(255, 255, 255, 0.8), $warningt); */
    }
  }

  display: flex;
  flex-wrap: wrap;
  position: relative;

  .v-offset {
    display: inline-block;
    flex: 0 1;
    margin-top: 0;
    margin-left: 0;
    margin-right: auto;
    margin-bottom: 0 !important;
    max-width: auto;
    padding: 0 16px 0;
  }

  .v-card {
    border-radius: 4px;
    flex: 0 1 auto;
    width: 85px;
    height: 85px;
  }

  .v-list-item__action {
    .v-btn--fab {
      padding: 0 !important;
    }
  }

  .v-card__text {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;

    .v-list {
      text-align: left;

      .v-list-item {
        max-height: 45px;
      }
    }
  }

  .v-card__actions {
    flex: 1 0 100%;

    .v-btn--fab {}
  }
}
</style>
