<template>
  <v-card
    v-on="$listeners"
    class="sso-message-card"
    elevation="4"
    outlined
    shaped
  >
    <v-list-item
      :class="['text-truncate', 'v-app-card-title', 'ma-0', 'sso-message-card-head', `msg-${type}`]"
      two-line
    >
      <v-icon
        v-if="localIcon"
        x-large
        v-text="localIcon"
        class="mr-3"
        :color="type"
      />
      <v-list-item-content
        :class="`${type}--text`"
      >
        <v-list-item-title class="text-h5">
          {{ title }}
        </v-list-item-title>
        <v-list-item-subtitle>
          {{ description }}
        </v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
    <v-card-text
      v-if="message"
      class="text-body-1"
      v-html="message"
    />
    <template
      v-else
    >
      <slot />
    </template>
    <DialogActions
      v-if="actions"
      ref="actions"
      :actions="actions"
      v-bind="actionOptions"
      :handler="handler"
    />
  </v-card>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: 'info'
    },
    icon: {
      type: String,
      default: 'default'
    },
    title: {
      type: String,
      default: undefined
    },
    description: {
      type: String,
      default: undefined
    },
    message: {
      type: undefined,
      default: null
    },
    actions: {
      type: [Array, Object, Function],
      default: null
    },
    actionOptions: {
      type: Object,
      default: () => ({
        flat: true
      })
    },
    handler: {
      type: Function,
      default: null
    }
  },
  data () {
    return {
    }
  },
  computed: {
    localIcon () {
      if (this.icon !== 'default') {
        return this.icon
      }
      let icon = 'fa-info-circle'
      switch (this.type) {
        case 'error':
          icon = 'fa-exclamation-circle'
          break
        case 'warning':
          icon = 'fa-exclamation-triangle'
          break
        case 'success':
          icon = 'fa-check-circle'
          break
        default:
          icon = 'fa-info-circle'
      }
      return icon
    }
  },
  methods: {
  }
}
</script>

<style lang="scss">
@import '@/styles/index.scss';
@import '~vuetify/src/styles/settings/_variables';
$start-color: $white;
$start-opacity: 0;
$end-opacity: 0.2;
.sso-message-card {
  &.v-card {
    border: none;
  }
  .v-list-item--link {
    cursor: default;
  }
  .sso-message-card-head {
    height: 70px;
    background: linear-gradient(to right, transparentize($start-color, $start-opacity), transparentize($brand-primary, $end-opacity));
    &.msg-info {
      background: linear-gradient(to right, transparentize($start-color, $start-opacity), transparentize($brand-info, $end-opacity));
    }
    &.msg-success {
      background: linear-gradient(to right, transparentize($start-color, $start-opacity), transparentize($brand-success, $end-opacity));
    }
    &.msg-error {
      background: linear-gradient(to right, transparentize($start-color, $start-opacity), transparentize($brand-danger, $end-opacity));
    }
    &.msg-warning {
      background: linear-gradient(to right, transparentize($start-color, $start-opacity), transparentize($brand-warning, $end-opacity));
    }
    &.msg-primary {
      background: linear-gradient(to right, transparentize($start-color, $start-opacity), transparentize($brand-primary, $end-opacity));
    }
    @media #{map-get($display-breakpoints, 'md-and-down')} {
      .v-app-card-title {
        max-width: 245px;
      }
    }
    @media only screen and (max-width: 1440px) {
      .v-app-card-title {
        max-width: 375px;
      }
    }
  }
}
</style>
