/**
 * module mutation.js
 */

import { set, toggle } from '@/utils/vuex'

export default {
  setGroupApps: set('groupApps'),
  toggleGroupApps: toggle('groupApps'),
  setShowDial: set('showDial'),
  toggleShowDial: toggle('showDial'),
  setLayout: set('layout'),
  toggleLayout (state) {
    if (state.layout === 'old') {
      state.layout = 'new'
    } else {
      state.layout = 'old'
    }
  }
}
