/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'

// Store functionality
import actions from './actions'
import getters from './getters'
import modules from './modules'
import mutations from './mutations'
import state from './state'
import router from '@/router'
const vuexLocal = new VuexPersistence({ storage: window.localStorage })

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  actions,
  getters,
  modules,
  mutations,
  state,
  plugins: [vuexLocal.plugin]
})
store.watch(st => st.account.user, (newValue, oldValue) => {
  if ((newValue || null) === null) router.push({ name: 'Login', query: router.currentRoute.query })
  if ((newValue !== oldValue) && newValue) router.push({ name: 'Dashboard' })
})

export default store
