/**
 * module state.js
 */

export default {
  activeClientApp: {
    id: null,
    name: null,
    description: null,
    logo: null
  }
}
